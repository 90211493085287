<template>
  <v-container :class="$vuetify.breakpoint.lgAndUp ? 'pl-2 pr-16 pt-5' : ''">
    <v-row :class="$vuetify.breakpoint.lgAndUp ? 'mt-6' : ''">
      <v-col cols="1"></v-col>
      <v-col
        :cols="$vuetify.breakpoint.lgAndUp ? 5 : 12"
        :class="$vuetify.breakpoint.lgAndUp ? 'mr-10' : 'pt-0'"
      >
        <v-data-table
          item-key="id"
          hide-default-footer
          disable-pagination
          style="width: 700px"
          disable-filtering
          disable-sort
          :headers="headers"
          :items="products"
          class="elevation-5"
        >
          <template #item.action="{ item }">
            <v-icon
              @click="addItem(item)"
              color="grey"
              v-if="currentItem != item"
              >mdi-plus</v-icon
            >
            <v-icon
              @click="addItem(item)"
              color="green"
              v-if="currentItem == item"
              >mdi-check</v-icon
            >
          </template>
          <template #item.product_price="{ item }">
            <div>{{ formatCalculate(item.product_price) }} €</div>
          </template>
          <!-- <template v-slot:item.quantity="{}">
            <v-text-field
              type="number"
              class="quantityline"
              step="any"
              min="0"
              v-model="number"
            ></v-text-field>
          </template>
          -->
        </v-data-table>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.lgAndUp ? 5 : 12"
        v-if="!this.$vuetify.breakpoint.mobile"
      >
        <v-card height="600px" elevation="5" color="white">
          <v-card color="grey lighten-4" flat tile>
            <v-card-subtitle class="text-center"
              ><b
                >Warenkorb von {{ this.$root.firstname }}
                {{ this.$root.lastname }}
              </b></v-card-subtitle
            >
          </v-card>

          <v-divider></v-divider>
          <v-card-subtitle v-if="selected == 0" class="text-center mt-3"
            >Keine Produkte ausgewählt!</v-card-subtitle
          >
          <v-sheet v-for="(item, j) in selected" :key="j">
            <v-simple-table>
              <template>
                <tbody>
                  <tr class="border_bottom">
                    <td>
                      <v-icon
                        color="grey"
                        small
                        @click="deleteSelected(item)"
                        class="mr-3"
                        >mdi-close</v-icon
                      >
                      {{ item.product_name }}
                    </td>
                    <td class="text-right">
                      {{ formatCalculate(item.product_price) }} €
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
          <v-row no-gutters dense>
            <v-col class="sum">
              <v-simple-table>
                <template>
                  <tbody>
                    <tr>
                      <td><b>Summe:</b></td>
                      <td class="text-right">{{ this.summe }} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
        <v-card-actions>
          <v-col>
            <v-btn block color="red darken-2" dark @click="confirmOrder()"
              >Jetzt bestellen</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-col>
      <v-col cols="1"> </v-col>
    </v-row>
    <v-dialog
      no-click-animation
      persistent
      v-model="cartDialog"
      v-if="this.$vuetify.breakpoint.mobile"
      overlay-color="white"
      overlay-opacity="1.0"
    >
      <v-col :cols="$vuetify.breakpoint.lgAndUp ? 5 : 12">
        <v-card height="600px" color="white">
          <v-card color="grey lighten-4" flat tile>
            <v-card-subtitle>
              <v-icon @click="cartDialog = false" class="mr-3"
                >mdi-close</v-icon
              >
              <b
                >Warenkorb von {{ this.$root.firstname }}
                {{ this.$root.lastname }}
              </b></v-card-subtitle
            >
          </v-card>

          <v-divider></v-divider>
          <v-card-subtitle v-if="selected == 0" class="text-center mt-3"
            >Keine Produkte ausgewählt!</v-card-subtitle
          >
          <v-sheet v-for="(item, j) in selected" :key="j">
            <v-simple-table>
              <template>
                <tbody>
                  <tr class="border_bottom">
                    <td>
                      <v-icon
                        color="grey"
                        small
                        @click="deleteSelected(item)"
                        class="mr-3"
                        >mdi-close</v-icon
                      >
                      {{ item.product_name }}
                    </td>
                    <td class="text-right">
                      {{ formatCalculate(item.product_price) }} €
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
          <v-row no-gutters dense>
            <v-col class="sum">
              <v-simple-table>
                <template>
                  <tbody>
                    <tr>
                      <td><b>Summe:</b></td>
                      <td class="text-right">{{ this.summe }} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
        <v-card-actions>
          <v-col>
            <v-btn block color="red darken-2" dark @click="confirmOrder()"
              >Jetzt bestellen</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-col>
    </v-dialog>
    <v-footer
      v-if="$vuetify.breakpoint.mobile"
      app
      color="grey lighten-1"
      class="pa-4"
    >
      <v-card-subtitle class="pl-1 pt-0 mt-1 pb-0"
        >Warenkorb:
        <span class="pl-1 pt-0 mt-1 pb-0">{{ this.selected.length }}</span>
      </v-card-subtitle>
      <v-spacer></v-spacer>
      <v-btn fab small @click="openCart()">
        <v-icon small>mdi-cart</v-icon>
      </v-btn>
    </v-footer>
    <v-snackbar
      bottom
      right
      v-model="snackbar"
      :color="snackbarcolor"
      height="40"
      text
    >
      <div class="text-center text-h6 ml-6 mr-6">{{ snackbartext }}</div>
    </v-snackbar>
  </v-container>
</template>
<script>
// import _ from "lodash";
import axios from "axios";
export default {
  name: "App",

  data: () => ({
    selected: [],
    products: [],
    allproducts: "",
    summe: "0.00",
    currentItem: "",
    snackbar: false,
    snackbarcolor: "",
    snackbartext: "",
    number: 0,
    employee_id: "",
    quantity: "",
    myorder: [],
    cartDialog: false,
    headers: [
      { action: "+", value: "action" },
      {
        text: "Produkt",
        align: "start",
        sortable: false,
        value: "product_name",
      },
      { text: "Preis", value: "product_price", sortable: false },
    ],
  }),
  components: {},
  watch: {
    selected: function () {
      this.calculate();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    deleteSelected(value) {
      let index = this.selected.indexOf(value);
      this.selected.splice(index, 1);
    },
    openCart() {
      this.cartDialog = true;
    },
    addItem(value) {
      this.currentItem = value;
      this.selected.push(value);
      setTimeout(() => {
        this.currentItem = [];
      }, 2000);
    },
    calculate() {
      let sum = 0;
      for (let value of this.selected) {
        let ergebnis = (sum += value.product_price);
        this.summe = ergebnis.toFixed(2);
      }
    },
    formatCalculate(value) {
      return value.toFixed(2);
    },
    // calculateProucts() {
    //   let products = "";
    //   if (this.selected.length < 2) {
    //     for (let value of this.selected) {
    //       let ergebnis = (products += value.product_name);
    //       this.allproducts = ergebnis;
    //     }
    //   } else {
    //     for (let value of this.selected) {
    //       let ergebnis = (products += "," + " " + value.product_name);
    //       ergebnis = ergebnis.substring(2);
    //       this.allproducts = ergebnis;
    //     }
    //   }
    // },
    async getData() {
      try {
        let response = await axios.get("/getproducts");
        this.products = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async confirmOrder() {
      if (this.selected == 0) {
        this.snackbar = true;
        this.snackbartext = "Du musst mindestens ein Produkt auswählen!";
        this.snackbarcolor = "red";
      } else {
        try {
          let result = await axios.post("/setOrder", {
            summe: this.summe,
            employee_id: this.$root.employee_id,
            products: this.selected,
            name: this.firstname + " " + this.lastname,
          });
          this.order = result;
          this.selected = [];
          this.summe = "0.00";
          this.$router.push({
            name: "OrderConfirmation",
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>
<style scoped>
.sum {
  border-top: 1px solid rgb(212, 212, 212);
  bottom: 0;
  position: absolute;
}
.quantityline {
  width: 50px;
}
tr.border_bottom td {
  border-bottom: 1px solid rgb(231, 231, 231);
}
.sticky {
  position: fixed;
  top: 10;
  left: 0;
}
</style>
