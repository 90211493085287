import Vue from "vue";

import VueRouter from "vue-router";

import Order from "../views/Order.vue";

import DailyOrder from "../views/DailyOrder.vue";

import OrderConfirmation from "../views/OrderConfirmation.vue";

import Login from "../views/Login.vue";

import axios from "axios";

import Administration from "../views/Administration.vue";

import MyAccount from "../views/MyAccount.vue";

import PrintOrdersPDF from "../components/PrintOrdersPDF.vue";

import PrintTotalPDF from "../components/PrintTotalPDF.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",

    name: "Order",

    component: Order,
  },
  {
    path: "/print/orders/:id",

    name: "PrintOrdersPDF",

    component: PrintOrdersPDF,
  },
  {
    path: "/print/total/:date",

    name: "PrintTotalPDF",

    component: PrintTotalPDF,
  },
  {
    path: "/confirm/order",

    name: "OrderConfirmation",

    component: OrderConfirmation,
  },

  {
    path: "/administration",

    name: "Administration",

    component: Administration,
  },

  {
    path: "/login",

    name: "Login",

    component: Login,
  },

  {
    path: "/order/:date",

    name: "DailyOrder",

    component: DailyOrder,
  },

  {
    path: "/account/:username",

    name: "MyAccount",

    component: MyAccount,
  },
];

const router = new VueRouter({
  mode: "history",

  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "DailyOrder") {
    next();
    return;
  }
  axios.post("/api/authorization/check").then((response) => {
    const isAuthenticated = response.data.success;
    if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
    else next();
  });
});

export default router;
