<template>
  <v-container>
    <v-col cols="12">
      <v-card class="pl-16 pr-16" elevation="5">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <v-card-title class="text-h5"
                >Persönliche Informationen</v-card-title
              >
              <v-spacer></v-spacer>
            </v-row>
            <hr />
            <v-row no-gutters>
              <v-card-subtitle
                class="pt-2 grey--text employeeactions"
                @click="changePasswordDialog = true"
              >
                <u>Passwort ändern</u>
              </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
              <v-card-subtitle
                class="pt-2 grey--text employeeactions"
                @click="Logout"
              >
                <u>Abmelden</u>
              </v-card-subtitle>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-text-field
              class="mr-2"
              color="grey"
              v-model="this.employee.firstname"
              readonly
              label="Vorname"
              outlined
              type="text"
              dense
              autocomplete="firstname"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              class="ml-2"
              color="grey"
              dense
              readonly
              v-model="this.employee.lastname"
              label="Nachname"
              outlined
              type="text"
              autocomplete="lastname"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              color="grey"
              class="mr-2"
              readonly
              dense
              v-model="this.employee.employee_id"
              label="Personalnummer"
              outlined
              type="text"
              autocomplete="username"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              color="grey"
              dense
              class="ml-2"
              readonly
              v-model="this.employee.success"
              label="Gruppe"
              outlined
              type="text"
              autocomplete="username"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="changePasswordDialog"
      persistent
      no-click-animation
      overlay-opacity="0.9"
      width="800px"
    >
      <v-toolbar color="grey lighten-3">
        <v-card-title>Passwort ändern</v-card-title>
      </v-toolbar>
      <v-card flat tile class="">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Passwort festlegen
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Passwort wiederholen
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Bestätigen </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row no-gutters class="mt-2">
                <v-col cols="12" class="pr-3">
                  <v-text-field
                    label="Neues Passwort"
                    type="password"
                    outlined
                    v-model="firstpassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn color="primary" @click="repeatPassword()">
                Fortfahren
              </v-btn>

              <v-btn text @click="cancelChangePassword()"> Abbrechen </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-text-field
                class="mt-1"
                v-model="secondpassword"
                type="password"
                label="Passwort wiederholen"
                outlined
              >
              </v-text-field>

              <v-btn color="primary" @click="checkPassword()">
                Fortfahren
              </v-btn>

              <v-btn text @click="cancelChangePassword()"> Abbrechen </v-btn>
              <v-card-text v-if="errormessage" class="red--text"
                >Deine Passwörter stimmen nicht überein!</v-card-text
              >
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-4" flat tile>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-card-subtitle class="pb-0 mb-5">
                      <u>Ihr Passwort wurde erfolgreich geändert!</u>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn @click="cancelChangePassword()" color="grey lighten-1">
                Schließen
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import sha256 from "sha256";

export default {
  name: "Employees",
  data: () => ({
    value: true,
    e1: 1,
    groups: [
      {
        key: 1,
        group: "Benutzer",
      },
      {
        key: 2,
        group: "Administrator",
      },
    ],
    search: "",
    employees: [],
    firstpassword: "",
    secondpassword: "",
    employee: {
      firstname: "",
      lastname: "",
      employee_id: "",
      group: "",
    },
    headers: [
      {
        text: "Personalnummer",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Name", value: "firstname", sortable: false },
      { text: "aktueller Saldo", value: "sum", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    changePasswordDialog: false,
    errormessage: false,
  }),
  components: {},
  mounted() {
    this.getData();
  },
  methods: {
    cancelChangePassword() {
      this.changePasswordDialog = false;
      (this.firstpassword = ""),
        (this.secondpassword = ""),
        (this.e1 = 1),
        (this.errormessage = false);
    },
    checkPassword() {
      if (this.firstpassword == this.secondpassword) {
        this.changePassword();
        this.e1 = 3;
        this.errormessage = false;
      } else {
        this.errormessage = true;
      }
    },
    repeatPassword() {
      if (this.firstpassword != "") {
        this.e1 = 2;
      }
    },
    async changePassword() {
      try {
        await axios.post(`/changePassword`, {
          employee_id: this.employee.employee_id,
          password: sha256(this.secondpassword),
        });
        this.Logout();
      } catch (e) {
        console.error(e);
      }
    },
    async Logout() {
      try {
        await axios.post("/api/authorization/logout");
        this.$router.push("/Login");
        this.$root.firstname = "";
        this.$root.lastname = "";
        this.$root.username = "";
        this.$root.employee_id = "";
        this.$root.success = "";
      } catch (e) {
        console.error(e);
      }
    },
    async getData() {
      try {
        let result = await axios.get(`/getMyData`, {
          params: {
            username: this.$route.params.username,
          },
        });
        this.employee = result.data;
      } catch (e) {
        console.error(e);
      }
    },
    // onrowclicked(item) {
    //   console.log(item);
    // },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
</style>
