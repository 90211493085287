<template>
  <v-container class="mx-auto mt-10">
    <!-- <v-container class="mx-auto mt-10" v-if="$vuetify.breakpoint.smAndUp"> -->
    <v-card flat tile color="transparent" width="900px" class="mx-auto">
      <v-card class="pa-5 mx-auto" elevation="6">
        <v-img src="/logo_long.png" contain class="shrink" width="400"></v-img>
      </v-card>
      <v-btn block class="mt-5" elevation="0" outlined @click="generatePDF()"
        >PDF herunterladen</v-btn
      >
      <v-card class="mx-auto mt-5" elevation="4" flat outlined>
        <v-card-title v-if="$vuetify.breakpoint.smAndUp"
          >Bestellungen vom {{ this.$route.params.date | formatDate }}
        </v-card-title>
        <v-card-title v-else
          >Bestellungen vom <br />{{ this.$route.params.date | formatDate }}
        </v-card-title>
      </v-card>
      <v-list
        outlined
        v-for="(item, j) in orders"
        :key="j"
        class="mt-4 mx-auto"
        elevation="4"
      >
        <v-list-item dense>
          <div class="font-weight-bold">
            {{ item[0].firstname }}
            {{ item[0].lastname }}
          </div>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(entry, i) in item" :key="i" dense>
          <div>
            {{ entry.product }}
          </div>
          <v-spacer></v-spacer>
          <div>{{ entry.price | parsePrice }} €</div>
        </v-list-item>
      </v-list>
      <v-card class="mt-5" v-if="this.noEntries">
        <v-card-subtitle class="text-center"
          >Keine Bestellungen vorhanden</v-card-subtitle
        >
      </v-card>
      <v-dialog
        v-model="verificationDialog"
        overlay-opacity="0.9"
        width="30%"
        persistent
      >
        <v-card width="100%" class="pa-8">
          <v-card-title>Verifizierung</v-card-title>
          <v-card-subtitle class="mt-2"
            >Bitte geben Sie den einmal Code aus der E-Mail
            ein.</v-card-subtitle
          >
          <v-otp-input
            :disabled="validating"
            class="pl-5 pr-5"
            v-model="otp"
            length="6"
            dense
            type="number"
          ></v-otp-input>

          <v-btn
            width="70%"
            block
            elevation="0"
            class="mt-5"
            @click="validateCode"
            >Bestätigen</v-btn
          >
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
  <!-- <v-container class="mx-auto" v-else>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="pa-5 mx-auto">
          <v-img
            src="/logo_long.png"
            contain
            class="shrink"
            width="400"
          ></v-img>
        </v-card>
        <v-card class="mx-auto mt-5" elevation="4" flat outlined>
          <v-card-title
            >Bestellungen vom <br />{{ this.$route.params.date | formatDate }}
          </v-card-title>
        </v-card>
        <v-list
          outlined
          v-for="(item, j) in orders"
          :key="j"
          class="mt-4 mx-auto"
          elevation="4"
        >
          <v-list-item dense>
            <div class="font-weight-bold">
              {{ item[0].firstname }}
              {{ item[0].lastname }}
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-for="(entry, i) in item" :key="i" dense>
            <div>
              {{ entry.product }}
            </div>
            <v-spacer></v-spacer>
            <div>{{ entry.price | parsePrice }} €</div>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container> -->
</template>
<script>
import { jsPDF } from "jspdf";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

export default {
  name: "DailyOrder",
  data: () => ({
    noEntries: false,
    error: false,
    validating: false,
    orders: [],
    otp: "",
    verificationDialog: false,
  }),
  components: {},
  mounted() {
    let validatet = localStorage.getItem(
      `orderValidation-${this.$route.params.date}`
    );
    if (validatet != null || this.$root.employee_id) {
      this.getData();
    } else {
      this.verificationDialog = true;
    }
  },
  watch: {
    otp(newOtp) {
      if (newOtp.length === 6) {
        this.validateCode();
        this.validating = true;
      }
    },
  },
  methods: {
    async validateCode() {
      this.validating = true;
      let response = await axios.get("/checkOrderValidation", {
        params: {
          date: this.$route.params.date,
          otp: this.otp,
        },
      });
      if (response.data.success) {
        localStorage.setItem(
          `orderValidation-${this.$route.params.date}`,
          JSON.stringify(response.data)
        );
        this.validating = false;
        this.verificationDialog = false;
        this.getData();
        this.$notify({
          group: "notify",
          type: "success",
          title: "Erfolgreich...",
          text: "Der Zugang wurde bestätigt!",
        });
      } else {
        this.validating = false;
        this.otp = "";
        this.error = true;
        this.$notify({
          group: "notify",
          type: "error",
          title: "Fehlgeschlagen...",
          text: "Bitte überprüfen Sie den Code!",
        });
      }
    },
    async getData() {
      try {
        let response = await axios.get(`/getOrdersFromDate`, {
          params: {
            date: this.$route.params.date,
          },
        });
        if (response.data.length > 0) {
          this.orders = _.groupBy(response.data, "employee_id");
        } else {
          this.noEntries = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    generatePDF() {
      const doc = new jsPDF();
      let yPosition = 45;
      const lineHeight = 8;
      const margin = 15;
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      doc.addImage("/logo_long.png", "PNG", margin, 10, 80, 15);

      const date = this.$route.params.date;
      doc.setFontSize(12);
      doc.text(
        `Datum: ${moment(date).format("DD.MM.YYYY")}`,
        pageWidth - margin - 45,
        20
      );

      Object.keys(this.orders).forEach((key) => {
        const employeeOrders = this.orders[key];
        const { firstname, lastname } = employeeOrders[0];

        // Check if a new page is needed before adding a new employee
        if (
          yPosition + lineHeight * (employeeOrders.length + 2) >
          pageHeight - margin
        ) {
          doc.addPage();
          yPosition = margin;
        }

        doc.setFontSize(16);
        doc.text(`${firstname} ${lastname}`, margin, yPosition);
        yPosition += lineHeight;

        doc.setFontSize(12);
        employeeOrders.forEach((order) => {
          // Check if a new page is needed before adding a new order
          if (yPosition + lineHeight > pageHeight - margin) {
            doc.addPage();
            yPosition = margin;
          }
          doc.text(
            `${order.product}: ${order.price.toFixed(2)} €`,
            margin,
            yPosition
          );
          yPosition += lineHeight;
        });

        yPosition += lineHeight; // Abstand zwischen den Mitarbeitern
      });

      doc.save("orders.pdf");
    },
  },
  filters: {
    parsePrice: function (value) {
      return value.toFixed(2);
    },
    formatDate: function (value) {
      moment.locale("de");
      return moment(value).format("DD. MMMM YYYY");
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
</style>
