<template>
  <v-container>
    <v-col cols="12">
      <v-card class="pl-16 pr-16" elevation="5">
        <v-row>
          <v-col cols="9">
            <v-row no-gutters>
              <v-card-title class="text-h5">Alle Mitarbeiter</v-card-title>
              <v-spacer></v-spacer>
            </v-row>
            <hr />
            <v-row no-gutters>
              <v-card-subtitle
                class="pt-2 grey--text employeeactions"
                @click="addEmployeeDialog = true"
              >
                <u>Mitarbeiter hinzufügen</u>
              </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text employeeactions">
                <u>Mitarbeiterliste drucken</u>
              </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
              <v-card-subtitle
                class="pt-2 grey--text employeeactions"
                @click="getData()"
              >
                <u>Mitarbeiter aktualisieren</u>
              </v-card-subtitle>
            </v-row>
          </v-col>
          <v-col cols="3" class="mt-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Durchsuchen..."
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="tablehover"
              :headers="headers"
              sort-by="lastname"
              :items="employees"
              :search="search"
              fixed-header
              hide-default-footer
              disable-pagination
            >
              <template #item.lastname="{ item }">
                {{ item.lastname }} {{ item.firstname }}
              </template>
              <template #item.actions="{ item }">
                <v-icon color="red" @click="onrowclicked(item)">
                  mdi-delete-outline
                </v-icon>
              </template>
              <template #item.sum="{ item }">
                {{ item.sum }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="addEmployeeDialog"
      persistent
      no-click-animation
      overlay-opacity="0.9"
      width="800px"
    >
      <v-toolbar color="grey lighten-3">
        <v-card-title>Mitarbeiter hinzufügen</v-card-title>
      </v-toolbar>
      <v-card flat tile class="">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Persönliche Daten
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Benutzername überprüfen
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Bestätigen </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row no-gutters class="mt-5">
                <v-col cols="6" class="pr-3">
                  <v-text-field
                    label="Vorname"
                    outlined
                    v-model="employee.firstname"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-3">
                  <v-text-field
                    label="Nachname"
                    outlined
                    v-model="employee.lastname"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pr-3">
                  <v-text-field
                    label="Personalnummer"
                    outlined
                    v-model="employee.employee_id"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-3">
                  <v-select
                    outlined
                    label="Gruppe"
                    v-model="employee.group"
                    :items="groups"
                    item-text="group"
                  ></v-select>
                </v-col>
              </v-row>
              <v-btn color="primary" @click="stepUsername()">
                Fortfahren
              </v-btn>

              <v-btn text @click="cancelAddingEmployee()"> Abbrechen </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-text-field
                class="mt-5"
                readonly
                v-model="employee.username"
                label="Benutzername"
                outlined
              >
              </v-text-field>

              <v-btn color="primary" @click="e1 = 3"> Fortfahren </v-btn>

              <v-btn text @click="cancelAddingEmployee()"> Abbrechen </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-4" flat tile>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-card-subtitle class="pb-0">
                      <u> Vollständiger Name</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0 mb-5">
                      {{ this.employee.firstname }}
                      {{ this.employee.lastname }}
                    </v-card-title>
                    <v-card-subtitle class="pb-0">
                      <u> Benutzername</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0">
                      {{ this.employee.username }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="6">
                    <v-card-subtitle class="pb-0">
                      <u> Gruppe</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0 mb-5">
                      {{ this.employee.group }}
                    </v-card-title>
                    <v-card-subtitle class="pb-0">
                      <u> Personalnummer</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0">
                      {{ this.employee.employee_id }}
                    </v-card-title>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn color="primary" @click="addEmployee()"> Hinzufügen </v-btn>

              <v-btn text @click="cancelAddingEmployee()"> Abbrechen </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteEmployeeDialog" width="500px">
      <v-toolbar color="grey lighten-3">
        <v-card-title>Mitarbeiter wirklich löschen?</v-card-title>
      </v-toolbar>
      <v-card flat tile class="pa-5">
        <v-btn color="primary" @click="deleteEmployee()"> Bestätigen </v-btn>

        <v-btn text @click="cancelDeleteEmployee()"> Abbrechen </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "Employees",
  data: () => ({
    value: true,
    deleteEmployeeDialog: false,
    e1: 1,
    groups: [
      {
        key: 1,
        group: "user",
      },
      {
        key: 2,
        group: "admin",
      },
    ],
    search: "",
    employees: [],
    employeeToDelete: {},
    employee: {
      firstname: "",
      lastname: "",
      employee_id: "",
      group: "",
    },
    headers: [
      {
        text: "Personalnummer",
        align: "start",
        sortable: false,
        value: "employee_id",
      },
      { text: "Name", value: "lastname", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    addEmployeeDialog: false,
  }),
  components: {},
  mounted() {
    this.getData();
  },
  methods: {
    cancelDeleteEmployee() {
      this.deleteEmployeeDialog = false;
      this.employeeToDelete = {};
    },
    cancelAddingEmployee() {
      this.addEmployeeDialog = false;
      this.employee = {};
      this.e1 = 1;
    },
    onrowclicked(item) {
      this.deleteEmployeeDialog = true;
      this.employeeToDelete = item;
    },
    async stepUsername() {
      if (
        this.employee.firstname != "" &&
        this.employee.lastname != "" &&
        this.employee.employee_id != "" &&
        this.employee.group != ""
      ) {
        let firstname = this.employee.firstname.toLowerCase();
        let lastname = this.employee.lastname.toLowerCase();
        let firstchar = firstname.substring(0, 1);
        let twofirstchars = firstname.substring(0, 2);
        let defaultUsername = firstchar + "." + lastname;
        let premiumUsername = twofirstchars + "." + lastname;
        let checkUsername = await axios.get("/checkUsername", {
          params: {
            username: defaultUsername,
          },
        });
        let checkedUsername = checkUsername;
        if (checkedUsername.data) {
          this.employee.username = premiumUsername;
          this.e1 = 2;
        } else {
          this.employee.username = defaultUsername;
          this.e1 = 2;
        }
      }
    },
    async getData() {
      try {
        let result = await axios.get(`/getAllEmployees`);
        this.employees = _.orderBy(result.data, "lastname");
      } catch (e) {
        console.error(e);
      }
    },
    async addEmployee() {
      try {
        await axios.post(`/addEmployee`, {
          employee: this.employee,
        });
        this.employee = {};
        this.addEmployeeDialog = false;
        this.e1 = 1;
      } catch (e) {
        console.error(e);
      }
    },
    async deleteEmployee() {
      try {
        await axios.post("/deleteEmployee", {
          employee: this.employeeToDelete,
        });
        this.deleteEmployeeDialog = false;
        this.employeeToDelete = {};
        this.getData();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
</style>
