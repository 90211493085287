<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-3">
        <v-card class="pl-16 pr-16 pb-10" tile>
          <v-row>
            <v-col cols="12">
              <v-row no-gutters>
                <v-toolbar flat width="100%">
                  <v-card-title class="text-h5 mt-3 mb-3 pa-0"
                    >Gesamtbericht
                  </v-card-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>
                    {{ formatDate2(findDate) }}
                  </v-toolbar-title>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>mdi-arrow-left </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                    :disabled="this.findDate == this.getDate"
                  >
                    <v-icon small> mdi-arrow-right</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-spacer></v-spacer>
              </v-row>
              <hr />
              <v-row no-gutters>
                <v-card-subtitle
                  class="pt-2 grey--text employeeactions"
                  @click="toPdfPrint()"
                >
                  <u>Gesamtbericht drucken</u>
                </v-card-subtitle>
                <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
                <v-card-subtitle
                  class="pt-2 grey--text employeeactions"
                  @click="getData()"
                >
                  <u>Gesamtbericht aktualisieren</u>
                </v-card-subtitle>
              </v-row>
            </v-col>
            <v-card width="100%" flat tile height="580px" class="overflowBox">
              <v-col cols="12">
                <v-list
                  outlined
                  v-for="(item, j) in orders"
                  :key="j"
                  class="mb-4"
                  elevation="4"
                >
                  <v-list-item dense>
                    <div>{{ item.lastname }} {{ item.firstname }}</div>
                    <v-spacer></v-spacer>
                    <div>{{ item.total }} €</div>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-card>

            <!-- <v-row no-gutters v-if="this.totalAmount.total != null">
              <v-col cols="12" class="pa-2">
                <v-card elevation="8">
                  <v-list class="mb-7">
                    <v-list-item dense>
                      <div class="font-weight-bold">Gesamtbetrag:</div>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item dense>
                      <v-spacer></v-spacer>
                      <div>{{ formatCalculate(this.totalAmount.total) }} €</div>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import _ from "lodash";
import moment from "moment";
import axios from "axios";

export default {
  name: "AllOrdersReport",
  data: () => ({
    value: true,
    currentEmployee: {},
    getDate: moment().format("YYYY-MM"),
    findDate: moment().format("YYYY-MM"),
    orders: [],
    totalAmount: "",
    employees: [],
  }),
  components: {},
  mounted() {
    this.getData();
  },
  watch: {
    findDate: {
      immediate: true,
      async handler() {
        await this.getData();
      },
    },
  },

  methods: {
    prev() {
      this.findDate = moment(this.findDate, "YYYY-MM")
        .subtract(1, "month")
        .format("YYYY-MM");
    },
    next() {
      this.findDate = moment(this.findDate, "YYYY-MM")
        .add(1, "month")
        .format("YYYY-MM");
    },

    onrowclicked(item) {
      this.currentEmployee = item;
      (this.getDate = moment().format("YYYY-MM")),
        (this.findDate = moment().format("YYYY-MM")),
        this.getData();
    },
    async getData() {
      try {
        let result = await axios.get(`/getOrdersReport`, {
          params: {
            date: this.findDate,
          },
        });
        this.orders = result.data;
      } catch (e) {
        console.error(e);
      }
    },
    formatDate: function (value) {
      moment.locale("de");
      return moment(value).format("dddd, DD.MM.YYYY");
    },
    formatDate2: function (value) {
      moment.locale("de");
      return moment(value).format("MMMM YYYY", "YYYY-MM");
    },
    formatCalculate(value) {
      return value.toFixed(2);
    },
    toPdfPrint() {
      let routeData = this.$router.resolve({
        name: "PrintTotalPDF",
        params: { date: this.findDate },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "PrintPDF",
      //   params: { id: this.currentEmployee.employee_id },
      //   target: "_blank",
      // });
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
.overflowBox {
  overflow: scroll;
}
.fixed-content {
  min-height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
