import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import axios from "axios";
import vueAwesomeCountdown from "vue-awesome-countdown";
import VueHtmlToPaper from "vue-html-to-paper";
import Notifications from "vue-notification";

Vue.use(vueAwesomeCountdown, "vac");
Vue.use(Notifications);
Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);
new Vue({
  router,
  vuetify,

  data() {
    return {
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      cn: "",
      groups: "",
      ready: false,
      success: "",
    };
  },

  mounted() {
    this.getAccount();
  },

  methods: {
    async getAccount() {
      try {
        let response = await axios.post("/api/authorization/check");
        if (response.data.success) {
          this.firstname = response.data.user.firstname;
          this.lastname = response.data.user.lastname;
          this.username = response.data.user.username;
          this.employee_id = response.data.user.employee_id;
          this.success = response.data.user.success;
        }
        this.ready = true;
      } catch (e) {
        console.error(e);
      }
    },
  },

  render(h) {
    return this.ready ? h(App) : null;
  },
}).$mount("#app");
