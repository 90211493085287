<template>
  <v-app>
    <v-app-bar
      v-if="viewMobileLayout"
      flat
      app
      color="red darken-4"
      clipped-left
      style="border-bottom: 3px solid white !important"
    >
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.name == 'xs'"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.smAndUp"
      :width="$vuetify.breakpoint.smAndUp ? 350 : 300"
      v-if="viewLayout"
      v-model="drawer"
      app
      clipped
      light
      src="@/assets/drawer-background.png"
    >
      <v-sheet class="sheetimage">
        <v-list light>
          <v-list-item>
            <router-link to="/">
              <v-list-item-avatar class="mb-6" size="70">
                <v-img :src="`/logo.png`"> </v-img>
              </v-list-item-avatar>
            </router-link>
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">
                Hallo {{ this.$root.firstname }},
              </v-list-item-title>
              <v-list-item-subtitle class="mt-2" style="font-size: 13px"
                >Benutzername: {{ this.$root.username }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="mt-2" style="font-size: 13px"
                >Aktueller Saldo:
                <span v-if="this.totalAmount.total != null"
                  >{{ formatCalculate(this.totalAmount.total) }} €</span
                >
                <span v-else>0,00 €</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div class="ml-10 mr-10 mb-4">
            <v-btn
              @click="Logout"
              color="grey"
              class="mt-2"
              dense
              outlined
              block
            >
              <span>Abmelden</span>
            </v-btn>
          </div>
        </v-list>
      </v-sheet>
      <v-divider></v-divider>
      <v-list light>
        <!-- <v-list-group prepend-icon="mdi-account">
          <template v-slot:activator>
            <v-list-item-title>Mein Konto</v-list-item-title>
          </template>
          <v-list-item active-class="" to="#orders">
            <v-icon class="mr-8" color="grey">mdi-format-list-text</v-icon>
            <v-list-item-title>Meine Bestellungen</v-list-item-title>
          </v-list-item>
          <v-list-item
            active-class="black--text"
            :to="`/account/info/${this.$root.username}`"
          >
            <v-icon class="mr-8" color="black--text">mdi-key</v-icon>
            <v-list-item-title>Passwort ändern</v-list-item-title>
          </v-list-item>
        </v-list-group> -->
        <v-list-item
          class="mb-1"
          v-if="!$vuetify.breakpoint.mobile"
          active-class="black--text"
          :to="`/account/${this.$root.username}#data`"
        >
          <v-icon class="mr-8" color="grey">mdi-account</v-icon>
          <v-list-item-title>Mein Konto</v-list-item-title>
        </v-list-item>
        <v-divider v-if="!$vuetify.breakpoint.mobile"></v-divider>
        <v-list-item
          class="mt-2"
          active-class="black--text"
          to="/administration/#employees"
          v-if="this.$root.success == 'admin' && !$vuetify.breakpoint.mobile"
        >
          <v-icon class="mr-8" color="grey">mdi-folder-account</v-icon>
          <v-list-item-title>Administration</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-spacer vertical></v-spacer>
      <v-divider v-if="!$vuetify.breakpoint.mobile"></v-divider>
    </v-navigation-drawer>
    <notifications group="notify" position="bottom center" class="mb-7 mr-7" />
    <v-main class="background">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";

export default {
  name: "App",
  data: () => ({
    currentDate: "",
    registerdialog: false,
    text: "",
    loading: false,
    totalAmount: "",
    navigationForAdmin: false,
    drawer: false,
    navigationForUser: false,
    employee_id: "",
    firstname: "",
    lastname: "",
    success: "",
    user: [],
    expectedOtp: "123456",
  }),
  mounted() {
    if (this.$route.name != "Login") this.getData();
  },
  watch: {
    $route() {
      if (this.$route.name == "Order") location.reload();
    },
  },
  computed: {
    viewLayout() {
      if (this.$route.name === "Login") return false;
      if (this.$route.name === "OrderConfirmation") return false;
      if (this.$route.name === "DailyOrder") return false;
      if (this.$route.name === "PrintOrdersPDF") return false;
      if (this.$route.name === "PrintTotalPDF") return false;

      return true;
    },
    viewMobileLayout() {
      if (this.$route.name === "Login") return false;
      if (!this.$vuetify.breakpoint.mobile) return false;
      if (this.$vuetify.breakpoint.mobile) return true;
      return true;
    },
  },

  methods: {
    formatCalculate(value) {
      return value.toFixed(2);
    },
    RegisterPage() {
      this.$router.push({
        name: "AddEmployee",
      });
    },
    allEmployeesPage() {
      this.$router.push({
        name: "AllEmployees",
      });
    },
    addProductsPage() {
      this.$router.push({
        name: "AddProducts",
      });
    },
    async getData() {
      try {
        if (this.$root.employee_id) {
          let getDate = moment().format("YYYY-MM");
          let result2 = await axios.get(`/getEmployeeOrdersTotal`, {
            params: {
              employee_id: this.$root.employee_id,
              date: getDate,
            },
          });
          this.totalAmount = result2.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async sendMail() {
      try {
        await axios.post("/sendMail");
      } catch (e) {
        console.error(e);
      }
    },
    async Logout() {
      try {
        await axios.post("/api/authorization/logout");
        this.$router.push("/Login");
        this.$root.firstname = "";
        this.$root.lastname = "";
        this.$root.username = "";
        this.$root.employee_id = "";
        this.$root.success = "";
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style>
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 8px;
  background: white;
}
::-webkit-scrollbar-thumb {
  background: hsl(14, 93%, 43%);
}

.background {
  background-image: url("./assets/background.png");
  background-size: cover;
}
.dialogcolor {
  background-color: white;
}
.sheetimage {
  background-image: url("./assets/sheet-background.png");
  background-size: 100%;
}
.selectedgroup {
  color: red;
}
</style>
