<template>
  <v-container fluid class="fill-height" style="background-color: grey">
    <v-card class="mx-auto margin" width="1000px">
      <v-row>
        <v-app-bar color="grey darken-3" class="no-print" fixed>
          <v-card-title class="white--text" style="font-size: 12px"
            >{{ this.employee.username }}
            {{ formatDate2(this.$route.params.date) }}.pdf</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn onclick="onclick=window.print()" text fab class="mr-10">
            <v-icon class="white--text">mdi-printer</v-icon>
          </v-btn>
        </v-app-bar>
        <v-col cols="12">
          <v-card class="pl-16 pr-16" tile flat>
            <v-row>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-toolbar flat width="100%">
                    <v-card-title class="mt-3 mb-3 pa-0" style="font-size: 16px"
                      >Bericht vom
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title style="font-size: 16px">
                      {{ formatDate2(this.$route.params.date) }}
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-spacer></v-spacer>
                </v-row>
                <hr />
              </v-col>
              <v-card width="100%" flat tile>
                <v-col cols="12">
                  <v-list
                    outlined
                    v-for="(item, j) in orders"
                    :key="j"
                    class="mb-4"
                    elevation="0"
                  >
                    <v-list-item dense>
                      <div class="font-weight-bold">
                        {{ item.lastname }}
                        {{ item.firstname }}
                      </div>
                      <v-spacer></v-spacer>
                      <div>{{ item.total }} €</div>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-card>

              <v-row no-gutters v-if="this.totalAmount.total != null">
                <v-col cols="12" class="pa-2">
                  <v-card elevation="0" outlined>
                    <v-list class="">
                      <v-list-item dense>
                        <div class="font-weight-bold">Gesamtbetrag:</div>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense>
                        <v-spacer></v-spacer>
                        <div>
                          {{ formatCalculate(this.totalAmount.total) }} €
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "PrintOrdersPDF",
  data: () => ({
    value: true,
    output: null,
    employee: [],
    currentEmployee: {},
    getDate: moment().format("YYYY-MM"),
    findDate: moment().format("YYYY-MM"),
    orders: [],
    totalAmount: "",
  }),
  components: {},
  mounted() {
    this.getData();
  },
  watch: {
    findDate: {
      immediate: true,
      async handler() {
        await this.getData();
      },
    },
  },

  methods: {
    prev() {
      this.findDate = moment(this.findDate, "YYYY-MM")
        .subtract(1, "month")
        .format("YYYY-MM");
    },
    next() {
      this.findDate = moment(this.findDate, "YYYY-MM")
        .add(1, "month")
        .format("YYYY-MM");
    },
    async getData() {
      try {
        let result = await axios.get(`/getOrdersReport`, {
          params: {
            date: this.$route.params.date,
          },
        });
        this.orders = result.data;
      } catch (e) {
        console.error(e);
      }
    },
    formatDate: function (value) {
      moment.locale("de");
      return moment(value).format("dddd, DD.MM.YYYY");
    },
    formatDate2: function (value) {
      moment.locale("de");
      return moment(value).format("MMMM YYYY", "YYYY-MM");
    },
    formatCalculate(value) {
      return value.toFixed(2);
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
.overflowBox {
  overflow: scroll;
}
.fixed-content {
  min-height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
.margin {
  margin-top: 80px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  } /* Black prints faster: h5bp.com/s */
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  } /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  } /* h5bp.com/t */
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .margin {
    margin-top: 0px;
  }
}
</style>
