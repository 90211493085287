<template>
  <v-container>
    <!-- <v-container class="bg-image"> -->
    <!-- Desktop version of SicknessList Request -->
    <v-row class="pa-5">
      <v-col cols="12">
        <v-card elevation="5">
          <v-tabs grow>
            <v-tab to="#data">
              <v-icon class="mr-3">mdi-folder-account</v-icon>
              Persönliche Informationen
            </v-tab>
            <v-tab to="#orders">
              <v-icon class="mr-3">mdi-format-list-text</v-icon>
              Meine Bestellungen
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <PersonalInformation v-if="this.$route.hash == '#data'" />
      <MyOrders v-if="this.$route.hash == '#orders'" />
    </v-row>
  </v-container>
</template>
<script>
import PersonalInformation from "../components/PersonalInformation.vue";
import MyOrders from "../components/MyOrders.vue";

export default {
  name: "Administration",
  data: () => ({
    snackbar: false,
    value: true,
    search: "",
    headers: [
      {
        text: "Personalnummer",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Name", value: "firstname", sortable: false },
      { text: "aktueller Saldo", value: "sum", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    password: "",
    firstname: "",
    lastname: "",
    employee_id: null,
    repeatpassword: "",
    accounts: "",
    logincheckbox: false,
  }),
  components: {
    PersonalInformation,
    MyOrders,
  },

  methods: {},
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
</style>
