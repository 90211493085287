<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="3" class="">
        <v-card flat tile>
          <v-card-subtitle>Mitarbeiter auswählen...</v-card-subtitle>
          <v-divider></v-divider>
        </v-card>
        <v-list height="700px" class="overflowBox pt-0">
          <v-list-item
            v-for="(item, j) in employees"
            :key="j"
            @click="onrowclicked(item)"
          >
            {{ item.lastname }} {{ item.firstname }}
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="9" class="mt-3">
        <div ref="content">
          <v-card class="pl-16 pr-16" height="755px" tile>
            <v-row>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-toolbar flat width="100%">
                    <v-card-title class="text-h5 mt-3 mb-3 pa-0"
                      >Bericht von {{ this.currentEmployee.firstname }}
                      {{ this.currentEmployee.lastname }}
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>
                      {{ formatDate2(findDate) }}
                    </v-toolbar-title>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small>mdi-arrow-left </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="next"
                      :disabled="this.findDate == this.getDate"
                    >
                      <v-icon small> mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-spacer></v-spacer>
                </v-row>
                <hr />
                <v-row no-gutters>
                  <v-card-subtitle
                    class="pt-2 grey--text employeeactions"
                    @click="toPdfPrint()"
                  >
                    <u>Bericht drucken</u>
                  </v-card-subtitle>
                  <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
                  <v-card-subtitle
                    class="pt-2 grey--text employeeactions"
                    @click="getData()"
                  >
                    <u>Bericht aktualisieren</u>
                  </v-card-subtitle>
                </v-row>
              </v-col>

              <v-card width="100%" flat tile height="490px" class="overflowBox">
                <v-col cols="12">
                  <v-list
                    outlined
                    v-for="(item, j) in orders"
                    :key="j"
                    class="mb-4"
                    elevation="4"
                  >
                    <v-list-item dense>
                      <div class="font-weight-bold">
                        {{ formatDate(item[0].shortdate) }}
                      </div>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-for="(entry, i) in item" :key="i" dense>
                      <div>
                        {{ entry.product }}
                      </div>
                      <v-spacer></v-spacer>
                      <div>{{ formatCalculate(entry.price) }} €</div>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-card>

              <v-row no-gutters v-if="this.totalAmount.total != null">
                <v-col cols="12" class="pa-2">
                  <v-card elevation="8">
                    <v-list class="mb-7">
                      <v-list-item dense>
                        <div class="font-weight-bold">Gesamtbetrag:</div>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense>
                        <v-spacer></v-spacer>
                        <div>
                          {{ formatCalculate(this.totalAmount.total) }} €
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import axios from "axios";

export default {
  name: "OrdersReport",
  data: () => ({
    value: true,
    output: null,
    currentEmployee: {},
    getDate: moment().format("YYYY-MM"),
    findDate: moment().format("YYYY-MM"),
    orders: [],
    totalAmount: "",
    employees: [],
  }),
  components: {},
  mounted() {
    this.getEmployees();
  },
  watch: {
    findDate: {
      immediate: true,
      async handler() {
        await this.getData();
      },
    },
  },

  methods: {
    prev() {
      this.findDate = moment(this.findDate, "YYYY-MM")
        .subtract(1, "month")
        .format("YYYY-MM");
    },
    next() {
      this.findDate = moment(this.findDate, "YYYY-MM")
        .add(1, "month")
        .format("YYYY-MM");
    },

    onrowclicked(item) {
      this.currentEmployee = item;
      (this.getDate = moment().format("YYYY-MM")),
        (this.findDate = moment().format("YYYY-MM")),
        this.getData();
    },
    async getData() {
      try {
        if (this.currentEmployee.employee_id) {
          let result = await axios.get(`/getEmployeeOrders`, {
            params: {
              employee_id: this.currentEmployee.employee_id,
              date: this.findDate,
            },
          });
          this.orders = _.groupBy(result.data, "shortdate");

          let result2 = await axios.get(`/getEmployeeOrdersTotal`, {
            params: {
              employee_id: this.currentEmployee.employee_id,
              date: this.findDate,
            },
          });
          this.totalAmount = result2.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getEmployees() {
      try {
        let result = await axios.get(`/getAllEmployees`);
        this.employees = result.data;
        this.employees = _.orderBy(this.employees, "lastname");
      } catch (e) {
        console.error(e);
      }
    },
    formatDate: function (value) {
      moment.locale("de");
      return moment(value).format("dddd, DD.MM.YYYY");
    },
    formatDate2: function (value) {
      moment.locale("de");
      return moment(value).format("MMMM YYYY", "YYYY-MM");
    },
    formatCalculate(value) {
      return value.toFixed(2);
    },
    toPdfPrint() {
      if (this.currentEmployee.employee_id) {
        let routeData = this.$router.resolve({
          name: "PrintOrdersPDF",
          params: { id: this.currentEmployee.employee_id },
        });
        window.open(routeData.href, "_blank");
      }
      // this.$router.push({
      //   name: "PrintPDF",
      //   params: { id: this.currentEmployee.employee_id },
      //   target: "_blank",
      // });
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
.overflowBox {
  overflow: scroll;
}
.fixed-content {
  min-height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
