<template>
  <v-container>
    <v-col cols="12">
      <v-card class="pl-16 pr-16" elevation="5">
        <v-row>
          <v-col cols="9">
            <v-row no-gutters>
              <v-card-title class="text-h5">Meine Bestellungen</v-card-title>
              <v-spacer></v-spacer>
            </v-row>
            <hr />
          </v-col>
          <v-col cols="12">
            <v-list
              outlined
              v-for="(item, j) in orders"
              :key="j"
              class="mb-4"
              elevation="4"
            >
              <v-list-item dense>
                <div class="font-weight-bold">
                  {{ formatDate(item[0].shortdate) }}
                </div>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item v-for="(entry, i) in item" :key="i" dense>
                <div>
                  {{ entry.product }}
                </div>
                <v-spacer></v-spacer>
                <div>{{ entry.price | parsePrice }} €</div>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-container>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import axios from "axios";

export default {
  name: "Products",
  data: () => ({
    value: true,
    orders: [],
  }),
  components: {},
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let result = await axios.get(`/getMyOrders`, {
          params: {
            employee_id: this.$root.employee_id,
          },
        });
        this.orders = _.groupBy(result.data, "shortdate");
      } catch (e) {
        console.error(e);
      }
    },
    formatDate: function (value) {
      moment.locale("de");
      return moment(value).format("dddd, DD.MM.YYYY");
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
</style>
