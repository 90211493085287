<template>
  <v-container>
    <v-row class="mt-6 paddingtop" align="center" justify="center">
      <v-col cols="12">
        <v-card-title class="justify-center" flat>
          <h1>Deine Bestellung wurde erfolgreich abgeschickt!</h1>
        </v-card-title>

        <countdown :end-time="new Date().getTime() + 5000">
          <v-card-subtitle
            slot="process"
            slot-scope="anyYouWantedScopName"
            class="text-center justify-centert"
            ><h3>
              {{
                `Du wirst automatisch ausgelogt in ${anyYouWantedScopName.timeObj.ceil.s}...`
              }}
            </h3></v-card-subtitle
          >
        </countdown>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "OrderConfirmation",
  data: () => ({
    send: false,
    counter: 0,
  }),
  mounted() {
    this.pageCounter();
  },
  methods: {
    pageCounter() {
      setTimeout(() => {
        this.routerpush();
      }, 5000);
    },
    routerpush() {
      axios.post("/api/authorization/logout");
      this.$router.push("/Login");
    },
  },
};
</script>
<style scoped>
.paddingtop {
  padding-top: 23%;
}
</style>
