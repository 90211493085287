<template>
  <v-app>
    <v-container class="bg-image fill-height align-self-center" fluid>
      <v-card
        color="white"
        flat
        class="mx-auto my-auto pl-12 pr-12 pb-12"
        max-height="1000"
        width="380"
      >
        <v-card-title class="justify-center" flat></v-card-title>
        <v-img
          src="../assets/logo.png"
          class="mx-auto mb-10 contain"
          width="200"
        ></v-img>
        <v-divider></v-divider>
        <v-form ref="form" @submit.prevent>
          <v-text-field
            color="red"
            v-model="user.username"
            append-icon="mdi-account"
            label="Benutzername"
            outlined
            type="username"
            autocomplete="username"
          ></v-text-field>
          <v-text-field
            color="red"
            v-model="user.password"
            append-icon="mdi-key"
            label="Passwort"
            outlined
            type="password"
            autocomplete="username"
          ></v-text-field>
          <v-card-actions>
            <v-btn
              type="submit"
              block
              color="#DC2B19"
              class="white--text mt-3"
              @click="Login"
              :timeout="2000"
              :disabled="!user.password"
            >
              Anmelden
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-footer absolute color="rgb(000, 000, 000, 0.4)">
        <v-card class="mx-auto" color="transparent" flat>
          <v-btn
            text
            small
            class="white--text"
            href="https://www.wagner-metalltechnik.de/impressum.html"
            >Impressum</v-btn
          >
          <v-btn
            text
            small
            class="white--text"
            href="https://www.wagner-metalltechnik.de/kontakt.html"
            >Kontakt</v-btn
          >
          <v-btn
            text
            small
            class="white--text"
            href="https://www.wagner-metalltechnik.de/datenschutz.html"
            >Datenschutz</v-btn
          >
        </v-card>
      </v-footer>
      <v-snackbar
        class="mb-4"
        :timeout="3000"
        v-model="snackbar"
        color="error"
        bottom
        text
      >
        <div class="text-center text-h6">Login fehlgeschlagen!</div>
        <div class="text-center">({{ this.reason }})</div>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
import axios from "axios";
import sha256 from "sha256";

export default {
  name: "Login",
  data: () => ({
    reason: "Benutzername oder Passwort falsch!",
    snackbar: false,
    snackbarcolor: "",
    snackbartext: "",
    value: true,
    employees: undefined,
    user: {
      username: "",
      password: "",
    },
    password: "",
    logincheckbox: false,
  }),
  methods: {
    async Login() {
      try {
        let response = await axios.get("/api/authorization/login", {
          params: {
            username: this.user.username,
            password: sha256(this.user.password),
          },
        });
        if (response.data.success) {
          this.$router.push("/");
        } else {
          this.snackbar = true;
        }
        this.employees = response.data;
        this.$refs.form.resetValidation();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/loginbackground.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.no-uppercase {
  text-transform: unset !important;
  color: rgb(105, 105, 105);
}
</style>
