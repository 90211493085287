<template>
  <v-container>
    <!-- <v-container class="bg-image"> -->
    <!-- Desktop version of SicknessList Request -->
    <v-row class="pa-5">
      <v-col cols="12">
        <v-card elevation="5">
          <v-tabs grow>
            <v-tab to="#employees">
              <v-icon class="mr-3">mdi-folder-account</v-icon>
              Mitarbeiter
            </v-tab>
            <v-tab to="#products">
              <v-icon class="mr-3">mdi-store</v-icon>
              Produkte
            </v-tab>
            <v-tab to="#reports">
              <v-icon class="mr-3">mdi-view-list-outline</v-icon>
              Bericht
            </v-tab>
            <v-tab to="#allreports">
              <v-icon class="mr-3">mdi-view-list-outline</v-icon>
              Abrechnung
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <Employees v-if="this.$route.hash == '#employees'" />
      <Products v-if="this.$route.hash == '#products'" />
      <OrdersReport v-if="this.$route.hash == '#reports'" />
      <AllOrdersReport v-if="this.$route.hash == '#allreports'" />
    </v-row>
  </v-container>
</template>
<script>
import Employees from "../components/Employees.vue";
import Products from "../components/Products.vue";
import OrdersReport from "../components/OrdersReport.vue";
import AllOrdersReport from "../components/AllOrdersReport.vue";

export default {
  name: "Administration",
  data: () => ({
    snackbar: false,
    value: true,
    search: "",
    headers: [
      {
        text: "Personalnummer",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Name", value: "firstname", sortable: false },
      { text: "aktueller Saldo", value: "sum", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    password: "",
    firstname: "",
    lastname: "",
    employee_id: null,
    repeatpassword: "",
    accounts: "",
    logincheckbox: false,
  }),
  components: {
    Products,
    Employees,
    OrdersReport,
    AllOrdersReport,
  },

  methods: {},
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
</style>
