<template>
  <v-container>
    <v-col cols="12">
      <v-card class="pl-16 pr-16" elevation="5">
        <v-row>
          <v-col cols="9">
            <v-row no-gutters>
              <v-card-title class="text-h5">Alle Produkte</v-card-title>
              <v-spacer></v-spacer>
            </v-row>
            <hr />
            <v-row no-gutters>
              <v-card-subtitle
                class="pt-2 grey--text employeeactions"
                @click="addProductDialog = true"
              >
                <u>Produkt hinzufügen</u>
              </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text employeeactions">
                <u>Produktliste drucken</u>
              </v-card-subtitle>
              <v-card-subtitle class="pt-2 grey--text"> | </v-card-subtitle>
              <v-card-subtitle
                class="pt-2 grey--text employeeactions"
                @click="getData()"
              >
                <u>Produkte aktualisieren</u>
              </v-card-subtitle>
            </v-row>
          </v-col>
          <v-col cols="3" class="mt-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Durchsuchen..."
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="tablehover"
              :headers="headers"
              sort-by="firstname"
              :items="products"
              :search="search"
              fixed-header
              hide-default-footer
              disable-pagination
            >
              <template #item.product_price="{ item }">
                <div>{{ formatCalculate(item.product_price) }} €</div>
              </template>
              <template #item.actions="{ item }">
                <v-icon color="red" @click="onrowclicked(item)">
                  mdi-delete-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="addProductDialog"
      persistent
      no-click-animation
      overlay-opacity="0.9"
      width="800px"
    >
      <v-toolbar color="grey lighten-3">
        <v-card-title>Produkt hinzufügen</v-card-title>
      </v-toolbar>
      <v-card flat tile class="">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Produktname
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Produktpreis
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Bestätigen </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row no-gutters class="mt-5">
                <v-col cols="12" class="pr-3">
                  <v-text-field
                    label="Produktname"
                    outlined
                    v-model="product.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn color="primary" @click="stepPrice()"> Fortfahren </v-btn>

              <v-btn text @click="cancelAddingProduct()"> Abbrechen </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-text-field
                class="mt-5"
                type="number"
                v-model="product.price"
                label="Preis"
                outlined
              >
              </v-text-field>

              <v-btn color="primary" @click="e1 = 3"> Fortfahren </v-btn>

              <v-btn text @click="cancelAddingProduct()"> Abbrechen </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-4" flat tile>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-card-subtitle class="pb-0">
                      <u> Produktname</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0 mb-5">
                      {{ this.product.name }}
                    </v-card-title>
                    <v-card-subtitle class="pb-0">
                      <u> Produktpreis</u>
                    </v-card-subtitle>
                    <v-card-title class="pt-0">
                      {{ this.product.price }} €
                    </v-card-title>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn color="primary" @click="addProduct()"> Hinzufügen </v-btn>

              <v-btn text @click="cancelAddingProduct()"> Abbrechen </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteProductDialog" width="500px">
      <v-toolbar color="grey lighten-3">
        <v-card-title>Produkt wirklich löschen?</v-card-title>
      </v-toolbar>
      <v-card flat tile class="pa-5">
        <v-btn color="primary" @click="deleteProduct()"> Bestätigen </v-btn>

        <v-btn text @click="cancelDeleteProduct()"> Abbrechen </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  name: "Products",
  data: () => ({
    value: true,
    deleteProductDialog: false,
    productToDelete: {},
    e1: 1,
    groups: [
      {
        key: 1,
        group: "Benutzer",
      },
      {
        key: 2,
        group: "Administrator",
      },
    ],
    search: "",
    products: [],
    product: {
      name: "",
      price: "0",
    },
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Name", value: "product_name", sortable: false },
      { text: "Preis", value: "product_price", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    addProductDialog: false,
  }),
  components: {},
  mounted() {
    this.getData();
  },
  methods: {
    cancelAddingProduct() {
      this.addProductDialog = false;
      this.product = {};
      this.e1 = 1;
    },
    cancelDeleteProduct() {
      this.deleteProductDialog = false;
      this.productToDelete = {};
    },
    stepPrice() {
      if (this.product.name != "") {
        this.e1 = 2;
      }
    },
    async getData() {
      try {
        let result = await axios.get(`/getAllProducts`);
        this.products = result.data;
      } catch (e) {
        console.error(e);
      }
    },
    formatCalculate(value) {
      return value.toFixed(2);
    },
    async addProduct() {
      try {
        await axios.post(`/addProduct`, {
          product: this.product,
        });
        this.product = {};
        this.addProductDialog = false;
        this.e1 = 1;
      } catch (e) {
        console.error(e);
      }
    },
    onrowclicked(item) {
      this.deleteProductDialog = true;
      this.productToDelete = item;
    },
    async deleteProduct() {
      try {
        await axios.post("/deleteProduct", {
          product: this.productToDelete,
        });
        this.deleteProductDialog = false;
        this.productToDelete = {};
        this.getData();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("../assets/background.png") !important;
  background-size: cover !important;
  opacity: 0.8;
}
.employeeactions {
  cursor: pointer;
}
.employeeactions:hover {
  color: green !important;
}
</style>
